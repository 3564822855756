export default {
    components: {},
    data() {
        return {
            entity_list: [
                {id: 1, name: `aaa`},
                {id: 2, name: `bbb`},
                {id: 3, name: `ccc`},
            ],
        };
    },
    methods: {},
    created() {
        // const __this = this;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
