import ToDoList from './component/to-do-list/index.vue';

export default {
    components: {
        ToDoList,
    },
    data() {
        return {};
    },
    methods: {},
    created() {
        // const __this = this;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
